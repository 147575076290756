import { useEffect, useState } from "react";

import InputText from "../../../../components/InputText/InputText";
import InputSelect from "../../../../components/InputSelect/InputSelect";
import ButtonWithBackgroundColor from "../../../../components/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import FormAddWork from "../FormAddWork/FormAddWork";
import FormAddTransport from "./components/FormAddTransport/FormAddTransport";
import FormCheckTransport from "./components/FormCheckTransport/FormCheckTransport";
import FormUpdateTransport from "./components/FormUpdateTransport/FormUpdateTransport";
import Loader from "../../../../components/Loader/Loader";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip";

import { useAppContext } from '../../../../context/AppContext';

import { getTransportByVIN } from "./utils/transportUtils";
import {
    validationLoginAndVIN,
    validationRecordName,
    validationMillage,
    validationTypeOfWork,
    validationWorks,
    validationTransportForm
} from "./utils/validationUtils";
import { regexVIN } from "../../../../utils/regex";
import { sendEmail, supportEmail } from "../../../../utils/email/emailUtils";

import transportApi from "../../../../utils/api/transportApi";
import maintenanceApi from "../../../../utils/api/maintenanceApi";

function FormAddRecord() {

    const [recordName, setRecordName] = useState('');
    const [recordNamevalidationText, setRecordNamevalidationText] = useState('');
    const [millage, setMillage] = useState('');
    const [millageValidationText, setMillageValidationText] = useState('');
    const [typeOfWork, setTypeOfWork] = useState('');
    const [validationTypeOfWorkText, setValidationTypeOfWorkText] = useState('');
    const [clientLogin, setClientLogin] = useState('');
    const [validationClientLoginText, setValidationClientLoginText] = useState('');
    const [VIN, setVIN] = useState('');
    const [validationVINText, setValidationVINText] = useState('');
    const [cost, setCost] = useState('');
    const [works, setWorks] = useState([]);
    const [isValidForm, setIsValidForm] = useState(false);
    const [messageValidForm, setMessageValidForm] = useState('');
    // Транспорт
    const [transport, setTransport] = useState({});
    // Формы
    const [isOpenTransportForm, setOpenTransportForm] = useState(false);
    const [isOpenUpdateTransportForm, setOpenUpdateTransportForm] = useState(false);
    const [isOpenCheckTransportForm, setIsOpenCheckTransportForm] = useState(false);
    // Инфотул
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');
    // Лоадер
    const [isLoadingVIN, setIsLoadingVIN] = useState(false);
    const [isLoadingAddTransport, setIsLoadingAddTransport] = useState(false);
    // Валидация для проверки
    const [isValidationLoginAndEmail, setIsValidationLoginAndEmail] = useState(false);

    const { dictionary, idUser, idDevice } = useAppContext();

    const options = [
        { value: 'Двигатель', label: dictionary.engine },
        { value: 'Подвеска', label: dictionary.suspenssion },
        { value: 'Трансмиссия', label: dictionary.transmission },
        { value: 'Разное', label: dictionary.other },
    ]

    const arrayTypesOfWork = ['Двигатель', 'Подвеска', 'Трансмиссия', 'Разное'];

    function submit() {
        if (isValidForm) {
            const record = {
                name: recordName,
                type: typeOfWork.value,
                cost: cost.toString(),
                author: idUser,
                loginClient:clientLogin,
                VIN: VIN,
                works: works,
                date: new Date(),
                millage: millage.toString(),
                idTransport: transport._id,
            }

            maintenanceApi.createMaintenance(idUser, idDevice, record)
                .then(() => {
                    setIsOpenInfoTooltip(true);
                    setTextInfoTooltip(dictionary.succesfull);
                    setRecordName('');
                    setMillage('');
                    setTypeOfWork('');
                    setClientLogin('');
                    setVIN('');
                    setCost('');
                    setWorks([]);
                })
                .catch((err) => {
                    console.log(err.validation);
                    setIsOpenInfoTooltip(true);
                    setTextInfoTooltip(dictionary.errorMessage)
                })
        }
    }

    function addWork(work) {
        const updateWorks = [...works, work]
        setWorks(updateWorks);
    }

    function removeWork(work) {
        const updateWorks = works.filter(element => element.id !== work.id);
        setWorks(updateWorks);
    }

    function addTransport(data) {
        data.VIN = VIN;
        setIsLoadingAddTransport(true);
        transportApi.createTransportByServiceEmployee(data, idDevice)
            .then((data) => {
                setTransport(data.transport);
                setOpenTransportForm(false);
                setIsLoadingAddTransport(false);
                setIsOpenCheckTransportForm(true);
            })
            .catch((err) => {
                openInfoTooltip(dictionary.errorMessageAddTransport);
                console.log(err);
                setIsLoadingAddTransport(false);
            });
    }

    function sendEmailUpdateTransport(data) {
        data.VIN = VIN;
        const recipientEmail = supportEmail;
        const themeEmail = dictionary.emailThemeUpdateTransport;
        const subject =
            `${dictionary.emailSubject1UpdateTransport} 
         ${dictionary.emailSubject2UpdateTransport}:
         ${dictionary.emailSubject3UpdateTransport}: ${data.type.label}
         ${dictionary.emailSubject4UpdateTransport}: ${data.VIN}
         ${dictionary.emailSubject5UpdateTransport}: ${data.brand} 
         ${dictionary.emailSubject6UpdateTransport}: ${data.model}`;

        sendEmail(recipientEmail, themeEmail, subject);
    }

    function updateTransport(data) {
        data.VIN = VIN;
        setIsLoadingAddTransport(true);
        transportApi.updateTransportByServiceEmployee(data, idDevice)
            .then((data) => {
                const updateTransport = data.transport;
                setTransport(updateTransport);
                setIsLoadingAddTransport(false);
                setOpenUpdateTransportForm(false);
                setIsOpenCheckTransportForm(true);
            })
            .catch((error) => {
                setIsLoadingAddTransport(false);
                openInfoTooltip(dictionary.errorMessageUpdateTransport);
                console.log(error)
            });
    }

    function openInfoTooltip(text) {
        setIsOpenInfoTooltip(true);
        setTextInfoTooltip(text);
    }

    function closeInfoTool() {
        setIsOpenInfoTooltip(false);
        setTextInfoTooltip('');
    }

    useEffect(() => {
        if (isValidationLoginAndEmail) {
            setIsLoadingVIN(true);
            getTransportByVIN(VIN, idDevice, setTransport, setOpenUpdateTransportForm, setIsLoadingVIN, setIsOpenCheckTransportForm, setOpenTransportForm)
        }
    }, [isValidationLoginAndEmail])

    // Управлять открытием форм работы с транспортом, закрывать их, если ВИН не валиден
    useEffect(() => {

        if (!regexVIN.test(VIN)) {
            setOpenTransportForm(false);
            setOpenUpdateTransportForm(false);
            setIsOpenCheckTransportForm(false);
            setIsLoadingAddTransport(false);
            setIsLoadingVIN(false);
        }

    }, [VIN])

    // Рассчитать стоимость работ
    useEffect(() => {
        let costWorks = 0;
        if (works.length > 0) {
            works.forEach((work) => {
                costWorks += Number(work.cost);
            })
        }
        setCost(costWorks);
    }, [works])

    async function validationForm() {
        let validName = validationRecordName(recordName, setRecordNamevalidationText, dictionary);
        let validMillage = validationMillage(millage, setMillageValidationText, dictionary);
        let validTypeOfWork = validationTypeOfWork(typeOfWork, arrayTypesOfWork, setValidationTypeOfWorkText, dictionary);
        let validWorks = validationWorks(works);
        let validLoginAndVIN = await validationLoginAndVIN(VIN, setValidationVINText, setValidationClientLoginText, dictionary, clientLogin, idDevice)
        setIsValidationLoginAndEmail(validLoginAndVIN);

        let validTransportForm = validationTransportForm(isOpenTransportForm, isOpenUpdateTransportForm);

        if (!validName || !validMillage || !validLoginAndVIN || !validTypeOfWork || !validWorks || !validTransportForm) {
            setIsValidForm(false);
        } else {
            setIsValidForm(true);
        }
    }

    // Валидация
    useEffect(() => {
        validationForm();
    }, [recordName, millage, typeOfWork, clientLogin, VIN, works, isOpenTransportForm, isOpenUpdateTransportForm])


    return (
        <div className="form-add-record">
            <p className="form-add-record__title">{dictionary.formAddRecordTitle}</p>
            <div className="form-add-record__container-input">
                <InputText
                    inputValue={recordName}
                    setInputChange={setRecordName}
                    placeholder={dictionary.placeholderMaintenaceName}
                    label={dictionary.labelMaintenaceName}
                    validationText={recordNamevalidationText}
                />
                <InputSelect
                    options={options}
                    value={typeOfWork}
                    setSelectValue={setTypeOfWork}
                    placeholder={dictionary.placeholderTypeOfWork}
                    validationText={validationTypeOfWorkText}
                />
                <InputText
                    inputValue={millage}
                    setInputChange={setMillage}
                    placeholder={dictionary.placeholderMillage}
                    label={dictionary.placeholderMillage}
                    validationText={millageValidationText}
                />
                <InputText
                    inputValue={clientLogin}
                    setInputChange={setClientLogin}
                    placeholder={dictionary.placeholderClientLogin}
                    label={dictionary.labelLoginClientName}
                    validationText={validationClientLoginText}
                />
                <div className="form-add-record__input-vin-with-loader">
                    <InputText
                        inputValue={VIN}
                        setInputChange={setVIN}
                        placeholder={dictionary.placeholderVIN}
                        label={dictionary.placeholderVIN}
                        validationText={validationVINText}
                    />
                    {isLoadingVIN ?
                        <div className="form-add-record__loader">
                            <Loader customStyle={'loader__gray__w25h25'} />
                        </div>
                        : <></>}
                </div>
                {isOpenTransportForm && regexVIN.test(VIN) ?
                    <FormAddTransport
                        onSubmit={addTransport}
                        isLoading={isLoadingAddTransport}
                    />
                    : <></>}
                {isOpenCheckTransportForm && regexVIN.test(VIN) ?
                    <FormCheckTransport
                        onSubmit={sendEmailUpdateTransport}
                        transport={transport}
                    />
                    : <></>}
                {isOpenUpdateTransportForm && regexVIN.test(VIN) ?
                    <FormUpdateTransport
                        onSubmit={updateTransport}
                        transport={transport}
                        isLoading={isLoadingAddTransport}
                    />
                    : <></>}
            </div>
            <div className="form-add-record__container-list-works">
                <FormAddWork onSubmit={addWork} arrayWorks={works} onClickRemoveItem={removeWork} />
            </div>
            <ButtonWithBackgroundColor text={dictionary.buttonAddRecord} isLoading={false} isDisabled={!isValidForm} onClick={submit} />
            <p className="form-add-work__error-message">{messageValidForm}</p>
            <InfoTooltip isOpen={isOpenInfoTooltip} closeInfoTool={closeInfoTool} text={textInfoTooltip} />
        </div>
    )
}

export default FormAddRecord;