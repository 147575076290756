import ItemListWork from "../ItemListWork/ItemListWork";

function ListWorks({ array, onClickRemoveItem }) {
    return (
        array && array.length > 0 ?
            < div className="list-works" >
                {
                    array.map((work) => {
                        return (
                            <div key={work.id}>
                                <ItemListWork
                                    itemId={work.id}
                                    name={work.name}
                                    cost={work.cost}
                                    onClickRemoveItem={() => onClickRemoveItem(work)}
                                />
                            </div>
                        )
                    })
                }
            </div >
            :
            <></>
    )

}

export default ListWorks;