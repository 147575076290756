import host from "./host";

class Api {
    constructor(data) {
        this.host = data.host;
    }

    // проверка статуса запроса
    _getResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            if (res.json()) {
                return res.json;
            } else {
                return Promise.reject(
                    `ошибка: ${res.status} - ${res.statusText}`
                );
            }
        }
    }

    // Создать запись
    createMaintenance(idUser, idDevice, data) {
        return fetch(`${this.host}/create-maintenances-by-service-employee/${idDevice}`,
            {
                method: 'POST',
                credentials: 'include', // добавляем заголовок Authorization с токеном
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idTransport: data.idTransport,
                    name: data.name,
                    type: data.type,
                    cost: data.cost,
                    works: data.works,
                    date: data.date,
                    millage: data.millage,
                    loginClient: data.loginClient,
                })
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    // Редактировать запись
    updateMaintenance(idUser, idDevice, data) {

        let updateObject = {
            idMaintenance: data.idMaintenance,
            idTransport: data.idTransport,
            loginClient: data.clientLogin,
        };

        if (data.name) {
            updateObject.name = data.name;
        }
        if (data.millage) {
            updateObject.millage = data.millage;
        }
        if (data.VIN) {
            updateObject.VIN = data.VIN;
        }
        if (data.type) {
            updateObject.type = data.type;
        }
        if (data.works) {
            updateObject.works = data.works;
            updateObject.cost = data.cost;
        }

        return fetch(`${this.host}/update-maintenances-by-service-employee/${idDevice}`,
            {
                method: 'PATCH',
                credentials: 'include', // добавляем заголовок Authorization с токеном
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateObject)
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    removeMaintenancesByServiceEmployee(idUser, idMaintenance, idDevice) {
        return fetch(`${this.host}/remove-maintenances-by-service-employee/${idMaintenance}/${idDevice}`,
            {
                method: 'DELETE',
                credentials: 'include', // добавляем заголовок Authorization с токеном
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    createBookingMaintenancesByServiceEmployee(idUser, idDevice, data) {
        return fetch(`${this.host}/create-booking-maintenances-by-service-employee/${idDevice}`,
            {
                method: 'POST',
                credentials: 'include', // добавляем заголовок Authorization с токеном
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: data.name,
                    dateOn: data.dateOn,
                    dateTo: data.dateTo,
                    clientLogin: data.login,
                })
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    getBookingMaintenancesByServiceEmployee(idUser, idDevice) {
        return fetch(`${this.host}/get-booking-maintenances-by-service-employee/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include', // добавляем заголовок Authorization с токеном
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    deleteBookingMaintenancesByServiceEmployee(idUser, idRecord, idDevice) {
        return fetch(`${this.host}/remove-booking-maintenances-by-service-employee/${idRecord}/${idDevice}`,
            {
                method: 'DELETE',
                credentials: 'include', // добавляем заголовок Authorization с токеном
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    updateBookingMaintenancesByServiceEmployee(idUser, idRecord, idDevice, nameBooking) {

        return fetch(`${this.host}/update-booking-maintenances-by-service-employee/${idDevice}`,
            {
                method: 'PATCH',
                credentials: 'include', // добавляем заголовок Authorization с токеном
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nameBooking: nameBooking,
                    idRecord: idRecord,
                })
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

}

const maintenanceApi = new Api({
    host: host,
});

export default maintenanceApi;