const ru = {
  buttonsNavigatorAddNewMaintenance: 'Добавить новую запись обслуживания',
  buttonsNavigatorClients: 'Клиенты',
  buttonsNavigatorCustomerRecord: 'Календарь записей клиентов',
  buttonAddWork: 'Добавить',
  buttonAddRecord: 'Отправить ТО',
  buttonAddTransport: 'Добавить транспорт',
  buttonCheckTransport: 'Есть ошибка? Отправить исправление',
  buttonAddCustomerRecord: 'Записать клиента',
  buttonRemove: 'Удалить',
  buttonUpdate: 'Редактировать',
  buttonSend: 'Отправить',
  buttonLogin: 'Авторизоваться',
  buttonLogout: 'Выйти из аккаунта',
  buttonMessanger: 'Открыть мессенджер',
  buttonsNavigatorMaintenances: 'Записи обслуживания',
  formAddRecordTitle: 'Новая запись ТО',
  formAddWorksTitle: 'Список работ и комплектующих',
  formAddTransportTitle: 'Транспорт не заведен в базу, пожалуйста, добавьте его',
  formUpdateTransportTitle: 'Пожалуйста, укажите отсутствующие параметры транспорта',
  formCheckTransportTitle: 'Данные транспорта',
  formAddCustomerRecordTitle: 'Записать клиента',
  formUpdateRecordTitle: 'Редактировать',
  formRemoveTitle: 'Удалить',
  placeholderMaintenaceName: 'Название ТО (напр. ТО-1)',
  placeholderMillage: 'Пробег',
  placeholderTypeOfWork: 'Выберите из списка Тип работ',
  placeholderClientLogin: 'Логин клиента',
  placeholderVIN: 'VIN',
  placeholderNameWork: 'Название работ или детали',
  placeholderCost: 'Стоимость',
  placeholderTransportBrand: 'Начните вводить бренд транспорта',
  placeholderTransportModel: 'Выберите из списка модель транспорта',
  placeholderTypeTransport: 'Выберите из списка Тип транспорта',
  placeholderSearchForm: 'Введите логин, ФИО или телефон',
  placeholderSearchFormMaintenances: 'Введите логин Клиента или VIN транспорта',
  placeholderLogin: 'Логин',
  placeholderPassword: 'Пароль',
  labelMaintenaceName: 'Название',
  labelLoginClientName: 'Клиент',
  labelNameWork: 'Название',
  labelInputDateTimer: 'Дата и время',
  engine: 'Двигатель',
  suspenssion: 'Подвеска',
  transmission: 'Трансмиссия',
  other: 'Другое',
  currency: 'руб',
  validationValue: 'Поле не заполнено',
  validationRegex: 'Не допустимые символы',
  validationFormWorks: 'Не заполнен список работ',
  validationValueLogin: 'Не заполнен логин пользователя',
  validationFindLogin: 'Проверьте логин пользователя',
  validationValueVIN: 'Длина VIN 17 символов',
  validationTimeEarly: 'Дата и время должны быть позже текущей',
  validationTimeNext: 'Дата и время должны быть не позже, чем через месяц',
  validationVINFind: 'транспорт с таким VIN не заведен у пользователя',
  validationTimeDateToEarly: 'Дата раньше, чем дата начала',
  validationTimeDateToNext: 'Дата не должна быть позже недели от начала работ',
  validationTextDateRangePicker: 'Диапазон дат не может превышать 2 месяца',
  succesfull: 'Успешно',
  unknownBrand: 'Бренд транспорта не из списка',
  unknownModel: 'Модель транспорта не из списка',
  typeTransportPassanger: 'Легковое авто',
  typeTransportCargo: 'Грузовое авто',
  typeTransportBike: 'Мототранспорт',
  typeTransportOther: 'Другой вид транспорта',
  valueTypeTransportPassanger: 'Легковое авто',
  valueTypeTransportCargo: 'Грузовое авто',
  valueTypeTransportBike: 'Мототранспорт',
  valueTypeTransportOther: 'Другой вид транспорта',
  emailThemeUpdateTransport: 'Редактирование транспорта',
  emailSubject1UpdateTransport: 'Добрый день!',
  emailSubject2UpdateTransport: 'Заявка на редактирование транспорта: ',
  emailSubject3UpdateTransport: 'Тип',
  emailSubject4UpdateTransport: 'VIN',
  emailSubject5UpdateTransport: 'Бренд',
  emailSubject6UpdateTransport: 'Модель',
  textInfoFormCheckTransport: 'Для редактивания внесите изменние в одно из полей',
  textYes: 'Да',
  textNo: 'Нет',
  textLogin: 'Логин',
  textClient: 'Клиент',
  textEmail: 'Email',
  textName: 'ФИО',
  textAuthor: 'Автор',
  textPhoneNumber: 'Телефон',
  textDateVisited: 'Дата визита',
  textClients: 'Клиенты',
  textType: 'Тип',
  textCost: '₽',
  textMaintenances: 'Записи',
  textListWorks: 'Список работ',
  textSummary: 'Итого',
  textMillage: 'Пробег',
  textKm: 'км',
  textQuestionRecoveryPassword: 'Забыли пароль?',
  textRecovery: 'Восстановить',
  titleLogin: 'Авторизация',
  textTitleApp: 'АвтоДневник',
  errorMessage: 'Произошла ошибка',
  errorMessageAddTransport: 'Произошла ошибка при добавлении транспорта',
  errorMessageUpdateTransport: 'Произошла ошибка при обновлении транспорта',
  errorMessageDaysDifferenceMaintenancesUpdate: 'Редактирование возможно не более 45ти дней с начала создания записи',
  errorLimitAuth: 'Повторная авторизация возможна через некоторое время',
  errorAuth: 'Проверьте логин и пароль',
  emailSupport: 'info@auto-dnevnik.ru',
  emailText: 'Добрый день! мой логин: ',
  emailTextRecoveryEmail: 'Добрый день! Прошу восстановить пароль, моя почта',
  emailThemeRecoveryPassword: 'Восстановление пароля',
  messagesCalendar: {
    allDay: 'весь день',
    previous: 'предыдущий',
    next: 'следующий',
    today: 'сегодня',
    month: 'месяц',
    week: 'неделя',
    day: 'день',
    agenda: 'повестка дня',
    date: 'дата',
    time: 'время',
    event: 'событие',
  },
}
const en = {
  buttonsNavigatorAddNewMaintenance: 'Add a new maintenance record',
  buttonsNavigatorClients: 'Clients',
  buttonsNavigatorCustomerRecord: 'Customer Records Calendar',
  buttonAddWork: 'Add',
  buttonAddRecord: 'Submit Maintenance',
  buttonAddTransport: 'Add Transport',
  buttonCheckTransport: 'Is there an error? Submit a correction',
  buttonAddCustomerRecord: 'Add a Customer Record',
  buttonRemove: 'Remove',
  buttonUpdate: 'Edit',
  buttonSend: 'Send',
  buttonLogin: 'Login',
  buttonLogout: 'Logout',
  buttonMessanger: 'Open Messenger',
  buttonsNavigatorMaintenances: 'Maintenance Records',
  formAddRecordTitle: 'New Maintenance Record',
  formAddWorksTitle: 'List of Works and Components',
  formAddTransportTitle: 'The transport is not in the database, please add it',
  formUpdateTransportTitle: 'Please specify missing transport parameters',
  formCheckTransportTitle: 'Transport Information',
  formAddCustomerRecordTitle: 'Add a Customer Record',
  formUpdateRecordTitle: 'Edit',
  formRemoveTitle: 'Remove',
  placeholderMaintenaceName: 'Maintenance Name (e.g., Maintenance-1)',
  placeholderMillage: 'Mileage',
  placeholderTypeOfWork: 'Select from the list of Work Types',
  placeholderClientLogin: 'Client Login',
  placeholderVIN: 'VIN',
  placeholderNameWork: 'Work or Component Name',
  placeholderCost: 'Cost',
  placeholderTransportBrand: 'Start typing the transport brand',
  placeholderTransportModel: 'Select from the list of transport models',
  placeholderTypeTransport: 'Select from the list of Transport Types',
  placeholderSearchForm: 'Enter login, full name, or phone',
  placeholderSearchFormMaintenances: 'Enter client login or transport VIN',
  placeholderLogin: 'Login',
  placeholderPassword: 'Password',
  labelMaintenaceName: 'Name',
  labelLoginClientName: 'Client',
  labelNameWork: 'Name',
  labelInputDateTimer: 'Date and Time',
  engine: 'Engine',
  suspenssion: 'Suspension',
  transmission: 'Transmission',
  other: 'Other',
  currency: '$',
  validationValue: 'Field is required',
  validationRegex: 'Invalid characters',
  validationFormWorks: 'The list of works is empty',
  validationValueLogin: 'Login is required',
  validationFindLogin: 'Check the user login',
  validationValueVIN: 'VIN must be 17 characters long',
  validationTimeEarly: 'Date and time must be later than the current one',
  validationTimeNext: 'Date and time must not be more than a month from now',
  validationVINFind: 'No transport with this VIN is registered to the user',
  validationTimeDateToEarly: 'Date is earlier than the start date',
  validationTimeDateToNext: 'Date must not be more than a week from the start of work',
  validationTextDateRangePicker: 'Date range cannot exceed 2 months',
  succesfull: 'Success',
  unknownBrand: 'Transport brand not in the list',
  unknownModel: 'Transport model not in the list',
  typeTransportPassanger: 'Passenger Car',
  typeTransportCargo: 'Cargo Car',
  typeTransportBike: 'Motorcycle',
  typeTransportOther: 'Other Transport Type',
  valueTypeTransportPassanger: 'Passenger Car',
  valueTypeTransportCargo: 'Cargo Car',
  valueTypeTransportBike: 'Motorcycle',
  valueTypeTransportOther: 'Other Transport Type',
  emailThemeUpdateTransport: 'Edit Transport',
  emailSubject1UpdateTransport: 'Good day!',
  emailSubject2UpdateTransport: 'Request to edit transport: ',
  emailSubject3UpdateTransport: 'Type',
  emailSubject4UpdateTransport: 'VIN',
  emailSubject5UpdateTransport: 'Brand',
  emailSubject6UpdateTransport: 'Model',
  textInfoFormCheckTransport: 'To edit, make changes to one of the fields',
  textYes: 'Yes',
  textNo: 'No',
  textLogin: 'Login',
  textClient: 'Client',
  textEmail: 'Email',
  textName: 'Full Name',
  textAuthor: 'Author',
  textPhoneNumber: 'Phone Number',
  textDateVisited: 'Visit Date',
  textClients: 'Clients',
  textType: 'Type',
  textCost: '$',
  textMaintenances: 'Records',
  textListWorks: 'List of Works',
  textSummary: 'Total',
  textMillage: 'Mileage',
  textKm: 'km',
  textQuestionRecoveryPassword: 'Forgot your password?',
  textRecovery: 'Recover',
  titleLogin: 'Login',
  textTitleApp: 'Auto Diary',
  errorMessage: 'An error occurred',
  errorMessageAddTransport: 'An error occurred while adding transport',
  errorMessageUpdateTransport: 'An error occurred while updating transport',
  errorMessageDaysDifferenceMaintenancesUpdate: 'Editing is possible no more than 45 days from the start of the record',
  errorLimitAuth: 'Reauthentication is possible after some time',
  errorAuth: 'Check the login and password',
  emailSupport: 'info@auto-dnevnik.ru',
  emailText: 'Good day! My login: ',
  emailTextRecoveryEmail: 'Good day! Please recover my password, my email',
  emailThemeRecoveryPassword: 'Password Recovery',
  messagesCalendar: {
    allDay: 'All Day',
    previous: 'Previous',
    next: 'Next',
    today: 'Today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Time',
    event: 'Event',
  },
}

const countriesCIS = [
  'ru-RU', // Россия
  'kk-KZ', // Казахстан
  'uk-UA', // Украина
  'be-BY', // Белоруссия
  'uz-UZ', // Узбекистан
  'az-AZ', // Азербайджан
  'hy-AM', // Армения
  'tk-TM', // Туркменистан
  'ky-KG', // Киргизия
  'tg-TJ', // Таджикистан
  'ro-MD', // Молдавия
  'ru', // Россия
  'kk', // Казахстан
  'uk', // Украина
  'be', // Белоруссия
  'uz', // Узбекистан
  'az', // Азербайджан
  'hy', // Армения
  'tk', // Туркменистан
  'ky', // Киргизия
  'tg', // Таджикистан
  'ro', // Молдавия
]

export { ru, en, countriesCIS };