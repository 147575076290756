import { useEffect, useState } from "react";
import { useAppContext } from '../../../../../../context/AppContext';
import InputSelectBrandTransport from "../InputSelectBrandTransport";
import InputSelectModelTransport from "../InputSelectModelTransport";
import InputSelectTypeTransport from "../InputSelectTypeTransport";
import { getBrandsList, getModelsList } from "../../utils/transportUtils";
import ButtonWithBoarder from "../../../../../../components/ButtonWithBorder/ButtonWithBorder";
import { validationStroke } from "./utils/validation";


function FormAddTransport({ onSubmit, isLoading }) {

    const { dictionary, idUser, idDevice } = useAppContext();

    // Транспорт
    const [brands, setBrands] = useState([{ label: dictionary.placeholderTransportBrand, value: dictionary.placeholderTransportBrand }]);
    const [models, setModels] = useState([{ label: dictionary.placeholderTransportModel, value: dictionary.placeholderTransportModel }]);
    // Инпуты
    const [inputTransportBrand, setInputTransportBrand] = useState({ value: '', label: dictionary.placeholderTransportBrand });
    const [inputBrandCurrentValue, setInputBrandCurrentValue] = useState(''); // значение инпута именно в момент ввода пользователем
    const [validationTransportBrandText, setValidationTransportBrandText] = useState('');
    const [inputTransportModel, setInputTransportModel] = useState({ value: '', label: dictionary.placeholderTransportModel });
    const [validationTransportModelText, setValidationTransportModelText] = useState('');
    const [inputTransportType, setInputTransportType] = useState({ value: '', label: dictionary.placeholderTypeTransport });
    const [validationTransportTypeText, setValidationTransportTypeText] = useState('');
    const [validationFormText, setValidationFormText] = useState('');
    // Форма
    const [isValidForm, setIsValidForm] = useState(false);
    // Лоадеры
    const [isLoadingBrand, setIsLoadingBrand] = useState(false);
    const [isLoadingModel, setIsLoadingModel] = useState(false);

    function submit() {
        if (isValidForm) {
            const data = {
                brand: inputTransportBrand.value,
                model: inputTransportModel.value,
                type: inputTransportType.value,
            }
            onSubmit(data);
        }
    }

    // Наполнение выбора брендов
    useEffect(() => {

        const validCurrenBrand = validationStroke(inputBrandCurrentValue, setValidationTransportBrandText, setValidationFormText, dictionary)

        if (validCurrenBrand) {
            setIsLoadingBrand(true);
            getBrandsList(inputBrandCurrentValue, idDevice)
                .then((brands) => {
                    setIsLoadingBrand(false);
                    setBrands(brands.brands)
                })
                .catch((error) => setIsLoadingBrand(false))
        }
    }, [inputBrandCurrentValue])

    //Наполнение выбора моделей
    useEffect(() => {
        if (inputTransportBrand !== '') {
            setIsLoadingModel(true);
            getModelsList(inputTransportBrand.value, idDevice)
                .then((models) => {
                    setIsLoadingModel(false);
                    setModels(models.models)
                })
                .catch((error) => setIsLoadingModel(false))
        }

    }, [inputTransportBrand])

    // Валидация
    useEffect(() => {

        let validType = validationStroke(inputTransportType.value, setValidationTransportTypeText, setValidationFormText, dictionary);
        let validBrand = validationStroke(inputTransportBrand.value, setValidationTransportBrandText, setValidationFormText, dictionary);
        let validModel = validationStroke(inputTransportModel.value, setValidationTransportModelText, setValidationFormText, dictionary);

        if (!validType || !validBrand || !validModel) {
            setIsValidForm(false);
        } else {
            setIsValidForm(true);
        }

    }, [inputTransportBrand, inputTransportModel, inputTransportType, inputBrandCurrentValue])

    return (
        <div className="form-add-transport">
            <p className="form-add-transport__title">{dictionary.formAddTransportTitle}</p>
            <InputSelectTypeTransport
                value={inputTransportType}
                setSelectValue={setInputTransportType}
                validationText={validationTransportTypeText}
            />
            <InputSelectBrandTransport
                brands={brands}
                handleInputChange={setInputBrandCurrentValue}
                value={inputTransportBrand}
                setSelectValue={setInputTransportBrand}
                placeholder={dictionary.placeholderTransportBrand}
                isLoading={isLoadingBrand}
                validationText={validationTransportBrandText}
            />
            {inputTransportBrand.value !== '' ?
                <InputSelectModelTransport
                    models={models}
                    value={inputTransportModel}
                    setSelectValue={setInputTransportModel}
                    placeholder={dictionary.placeholderTransportModel}
                    validationText={validationTransportModelText}
                    brand={inputTransportBrand.value}
                    isLoading={isLoadingModel}
                />
                : <></>}
            <div className="form-add-transport__button">
                <ButtonWithBoarder text={dictionary.buttonAddTransport} isDisabled={!isValidForm} onClick={submit} isLoading={isLoading} />
            </div>
            <p className="form-add-transport__validation-text">{validationFormText}</p>
        </div>
    )
}

export default FormAddTransport;