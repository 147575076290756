import { useAppContext } from '../../../../context/AppContext';

function ItemListClients({ login, name, phoneNumber, dateVisited, onClick, id }) {

    const { dictionary } = useAppContext();
    return (
        <div className="item-list-clients" onClick={onClick}>
            <div className="item-list-clients__container">
                <p className="item-list-clients__text">{dictionary.textLogin} {login}</p>
                <p className="item-list-clients__text">{dictionary.textDateVisited} {dateVisited}</p>
            </div>
            {name || phoneNumber ?
                <div className="item-list-clients__container">
                    <p className="item-list-clients__text">{dictionary.textName} {name}</p>
                    <p className="item-list-clients__text">{dictionary.textPhoneNumber} {phoneNumber}</p>
                </div>
                : <></>}
        </div>
    )
}

export default ItemListClients;