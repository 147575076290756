import React from "react";

import InputSelect from "../../../../../components/InputSelect/InputSelect";

import { useAppContext } from '../../../../../context/AppContext';
import Loader from "../../../../../components/Loader/Loader";

function InputSelectBrandTransport({ value, setSelectValue, placeholder, validationText, brands, isLoading, handleInputChange }) {

    const { dictionary } = useAppContext();

    let options = [
        {
            label: dictionary.unknownBrand,
            value: dictionary.unknownBrand,
        }
    ]

    if (brands) {
        brands.forEach((transport) => {
            const brand = {
                label: transport.brand,
                value: transport.brand,
            }
            options.push(brand);
        }
        )
    }

    return (
        <div className="input-with-loader">
            <InputSelect
                handleInputChange={handleInputChange}
                options={options}
                setSelectValue={setSelectValue}
                value={value} placeholder={placeholder}
                validationText={validationText}
            />
            {isLoading ?
                <div className="input-select__loader">
                    <Loader customStyle={'loader__gray__w25h25'} />
                </div>
                : <></>}
        </div>
    )
}

export default InputSelectBrandTransport;