function InputText({ inputValue, setInputChange, placeholder, validationText, label }) {

    function handleInputChange(e) {
        setInputChange(e.target.value);
    }

    return (
        <div className="input-container">
            <div className="input-with-label-container">
                {label && inputValue.length > 0 ?
                    <p className="input__label">{label}:</p>
                    : <></>}
                <input
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    className={`input ${validationText && validationText.length > 0 ? "input_error" : ""}`}
                    id="username-input" minLength="2"
                    required
                />
            </div>
            <span className="input__text-error" id="error-firstname">{validationText}</span>
        </div>
    )
}

export default InputText;