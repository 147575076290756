import transportApi from "../../../../utils/api/transportApi";
import { regexStrokeInput } from "../../../../utils/regex";

async function getTransportByVIN(VIN, idDevice, setTransport, setOpenUpdateTransportForm, setIsLoadingVIN, setIsOpenCheckTransportForm, setOpenTransportForm) {
    let transport = {};
    await transportApi.getTransportByVIN(VIN, idDevice)
        .then((data) => {
            if (data) {
                const transport = data.transport;
                if ((!transport.type || transport.type === '') || (!transport.brand || transport.brand === '') || (!transport.model || transport.model === '')) {
                    setTransport(data.transport);
                    setOpenUpdateTransportForm(true);
                    setIsLoadingVIN(false);
                } else {
                    setTransport(data.transport);
                    setIsLoadingVIN(false);
                    setIsOpenCheckTransportForm(true);
                }
            }
        })
        .catch((error) => {
            setIsLoadingVIN(false);
            if (error.message === 'Транспорт не найден') {
                setOpenTransportForm(true);
            }
            console.log(error)
        })
    return transport;
}

async function getBrandsList(brand, idDevice) {

    if (regexStrokeInput.test(brand)) {
        const brands = await transportApi.getTransportsBrandList(brand, idDevice);
        return brands;
    }
}

async function getModelsList(brand, idDevice) {
    if (regexStrokeInput.test(brand)) {
        const models = await transportApi.getTransportsModelList(brand, idDevice);
        return models;
    }
}

export {
    getTransportByVIN,
    getBrandsList,
    getModelsList,
}