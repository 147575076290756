import { useEffect, useState } from 'react';
import { useAppContext } from '../../../../context/AppContext';

import InfoTooltip from '../../../../components/InfoTooltip/InfoTooltip';
import InputText from '../../../../components/InputText/InputText';
import InputDateTimePicker from '../../../../components/InputDateTimePicker/InputDateTimePicker';
import ButtonWithBackgroundColor from '../../../../components/ButtonWithBackgroundColor/ButtonWithBackgroundColor'

import { validationLogin, validationDateOn, validationDateTo, validationRecordName } from './utils/FormAddCustomerRecordUtils';

function FormAddCustomerRecord({ onSubmit, isOpen, closeForm, isLoading }) {

    const { dictionary, idUser, idDevice } = useAppContext();

    const [clientLogin, setClientLogin] = useState('');
    const [validationClientLoginText, setValidationClientLoginText] = useState(dictionary.validationValue);
    const [recordName, setRecordName] = useState('');
    const [recordNamevalidationText, setRecordNamevalidationText] = useState(dictionary.validationValue);
    const [dateOnRecord, setDateOnRecord] = useState(new Date());
    const [dateToRecord, setDateToRecord] = useState(new Date());
    const [validationDateOnRecordText, setValidationDateOnRecordText] = useState(dictionary.validationValue);
    const [validationDateToRecordText, setValidationDateToRecordText] = useState(dictionary.validationValue);
    const [isValidForm, setIsValidForm] = useState(false);

    function submit() {
        if (isValidForm) {
            const data = {
                login: clientLogin,
                name: recordName,
                dateOn: dateOnRecord,
                dateTo: dateToRecord,
            }
            onSubmit(data);
        }
    }

    // Валидация
    async function validationForm() {
        const validLogin = await validationLogin(clientLogin, idDevice, setValidationClientLoginText, dictionary);
        const validDateOn = validationDateOn(dateOnRecord, setValidationDateOnRecordText, dictionary);
        const validDateTo = validationDateTo(dateToRecord, dateOnRecord, setValidationDateToRecordText, dictionary);
        let validRecordName = validationRecordName(recordName, setRecordNamevalidationText, dictionary);

        if (!validLogin || !validDateOn || !validDateTo || !validRecordName) {
            setIsValidForm(false);
        } else {
            setIsValidForm(true);
        }
    }

    useEffect(() => {
        validationForm();
    }, [recordName, clientLogin, dateOnRecord, dateToRecord])

    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={dictionary.formAddCustomerRecordTitle}
            customStyle={'form-add-customer-record'}
            children={
                <form className=''>
                    <InputText
                        inputValue={clientLogin}
                        setInputChange={setClientLogin}
                        placeholder={dictionary.placeholderClientLogin}
                        validationText={validationClientLoginText}
                        label={dictionary.labelLoginClientName}
                    />
                    <InputText
                        inputValue={recordName}
                        setInputChange={setRecordName}
                        placeholder={dictionary.placeholderMaintenaceName}
                        label={dictionary.labelMaintenaceName}
                        validationText={recordNamevalidationText}
                    />
                    <InputDateTimePicker handleSetDateTime={setDateOnRecord} validationText={validationDateOnRecordText} />
                    <InputDateTimePicker handleSetDateTime={setDateToRecord} validationText={validationDateToRecordText} />
                    <div className='form-add-customer-record__button-submit'>
                        <ButtonWithBackgroundColor isLoading={isLoading} text={dictionary.buttonAddCustomerRecord} onClick={submit} isDisabled={!isValidForm} />
                    </div>
                </form>
            }
        />
    )
}

export default FormAddCustomerRecord;