import Item from "../Item/Item";

function ListItems({ array, onClickItem }) {
    
    return (
        array.length > 0 ?
            <div className="list-items">
                {array.map((item) => {
                    return (
                        <div className="list__item" key={item._id}>
                            <Item item={item} onClick={onClickItem} />
                        </div>
                    )
                })}
            </div>
            : <></>

    )
}

export default ListItems;