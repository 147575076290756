import { useEffect, useState } from "react";
import { useAppContext } from '../../../../context/AppContext';
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip";
import InputText from "../../../../components/InputText/InputText";
import ButtonWithBorder from "../../../../components/ButtonWithBorder/ButtonWithBorder";

import { regexStrokeInput, regexNumericInput } from "../../../../utils/regex";
import { openWhatsAppChat } from "../../../../utils/messenger/messengerItils";

function FormClient({ isOpen, closeForm, text, clientData, update, isLoading }) {

    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [validNameText, setValidNameText] = useState('');
    const [validPhoneText, setValidPhoneText] = useState('');
    const [validForm, setValidForm] = useState(false);
    const [validWA, setValidWA] = useState(false);

    const { dictionary } = useAppContext();

    function onUpdate() {
        const data = {
            name: name,
            phoneNumber: phone,
            idClient: clientData._id,
        }
        update(data)
    }

    function openWA() {
        openWhatsAppChat(phone)
    }

    // Наполнить инпуты
    useEffect(() => {
       
        if (clientData.name) {
            setName(clientData.name)
        }
        if (clientData.phoneNumber) {
            setPhone(clientData.phoneNumber)
        }
    }, [clientData])

    // Валидация
    useEffect(() => {

        let validName = false;
        let validPhone = false;

        if (name === clientData.name && phone === clientData.phoneNumber) {

            setValidForm(false);
        } else {

            if (name !== '') {
                if (regexStrokeInput.test(name)) {
                    validName = true;
                    setValidNameText('');
                } else {
                    validName = false;
                    setValidNameText(dictionary.validationRegex);
                }
            } else {
                validName = false;
                setValidNameText(dictionary.validationValue);
            }

            if (phone !== '') {
                if (regexNumericInput.test(phone)) {
                    validPhone = true;
                    setValidWA(true);
                    setValidPhoneText('');
                } else {
                    validPhone = false;
                    setValidPhoneText(dictionary.validationRegex);
                    setValidWA(false);
                }
            } else {
                validPhone = false;
                setValidPhoneText(dictionary.validationValue);
                setValidWA(false);
            }

            if (validName && validPhone) {
                setValidForm(true);
            } else {
                setValidForm(false);
            }
        }

    }, [phone, name])

    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={text}
            children={
                <div className="form-client">
                    <InputText inputValue={name} setInputChange={setName} placeholder={dictionary.textName} validationText={validNameText} label={dictionary.textName} />
                    <InputText inputValue={phone} setInputChange={setPhone} placeholder={dictionary.textPhoneNumber} validationText={validPhoneText} label={dictionary.textPhoneNumber} />
                    <div className="form-client__button">
                        <ButtonWithBorder text={dictionary.buttonUpdate} onClick={onUpdate} isLoading={isLoading} isDisabled={!validForm} />
                    </div>
                    <div className="form-client__button">
                        <ButtonWithBorder text={dictionary.buttonMessanger} onClick={onUpdate} isDisabled={!validWA} onClick={openWA} />
                    </div>
                </div>
            }
        />
    )
}

export default FormClient;