import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/AppContext';
import NavigatorButton from "./Components/NavigatorButton/NavigatorButton";

function MainPage() {

    const navigate = useNavigate();

    const { dictionary } = useAppContext();

    function onClickAddNewMaintenance(e) {
        e.preventDefault();
        navigate('/add-record');
    }

    function onClickCustomerRecord(e) {
        e.preventDefault();
        navigate('/customer-record');
    }

    function onClickClients(e) {
        e.preventDefault();
        navigate('/clients');
    }

    function onClickMaintenances(e) {
        e.preventDefault();
        navigate('/maintenances');
    }

    return (
        <section className="main-page">
            <NavigatorButton text={dictionary.buttonsNavigatorAddNewMaintenance} onClick={onClickAddNewMaintenance} />
            <NavigatorButton text={dictionary.buttonsNavigatorClients} onClick={onClickClients} />
            <NavigatorButton text={dictionary.buttonsNavigatorCustomerRecord} onClick={onClickCustomerRecord} />
            <NavigatorButton text={dictionary.buttonsNavigatorMaintenances} onClick={onClickMaintenances} />
        </section>
    )
}

export default MainPage;