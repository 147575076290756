import { useAppContext } from '../../context/AppContext';

function ItemListWork({ itemId, name, cost, onClickRemoveItem }) {

    const { dictionary } = useAppContext();

    return (
        <div className="item-list-work" key={itemId}>
            <p className="item-list-work__text item-list-work__name">{name}</p>
            <div className="item-list-work__container">
                <p className="item-list-work__text">{cost} {dictionary.currency}</p>
                <p className="item-list-work__remove" onClick={onClickRemoveItem}>X</p>
            </div>
        </div>
    )

}

export default ItemListWork;