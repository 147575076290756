import React from 'react';

function InfoTooltip({ isOpen, closeInfoTool, text, children, customStyle, customStyleButtonCloseForm }) {

    function onClickOverlay(e) {
        if (e.target.classList.contains('info-tooltip__overlay')) {
            closeInfoTool();
        }
    }

    return (
        <div className={`info-tooltip info-tooltip__overlay ${isOpen ? "info-tooltip_show" : ""} `} onClick={onClickOverlay}>
            <button className={`info-tooltip__close-button ${customStyleButtonCloseForm ? customStyleButtonCloseForm : ""}`} type="button" onClick={closeInfoTool} />
            <div className={`info-tooltip__container ${customStyle ? customStyle : ''}`}>
                {text ?
                    <p className='tooltip__massage'>{text}</p>
                    : <></>}
                {children}
            </div>
        </div>
    )
}


export default InfoTooltip;