import { Calendar as RBCalendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/ru'; // Импортируйте русскую локализацию
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { countriesCIS } from '../../utils/translate';

const localizer = dayjsLocalizer(dayjs)

function Calendar({ arrayEvents, handleSelectEvent }) {

    const [events, setEvents] = useState([]);

    const { dictionary } = useAppContext();
    const userLocale = navigator.language;

    // Получить локаль и перевод
    useEffect(() => {
        if (countriesCIS.includes(userLocale)) {
            dayjs.locale('ru');
        } else {
            dayjs.locale('en');
        }
    }, [userLocale])

    useEffect(() => {
        if (arrayEvents && arrayEvents.length > 0) {

            const events = arrayEvents.map((data) => {

                const text = `${data.name} , ${dictionary.labelLoginClientName} ${data.client.login}`

                return {
                    id: data._id, // Включаем поле "id" в объект события
                    title: text,
                    start: new Date(data.dateOn),
                    end: new Date(data.dateTo), // Вы можете настроить конечную дату события по своему усмотрению
                };
            });

            setEvents(events);

        }
    }, [arrayEvents])

    const eventStyleGetter = (event, start, end, isSelected) => {
        const style = {
            backgroundColor: 'blue', // Цвет фона
            color: 'white', // Цвет текста
            borderRadius: '5px', // Скругленные углы
            border: '10px solid yellow', // Граница
        };
        return { style };
    };

    function selectEvent(e) {
        handleSelectEvent(e);
    }

    const messages = {
        allDay: 'весь день',
        previous: 'предыдущий',
        next: 'следующий',
        today: 'сегодня',
        month: 'месяц',
        week: 'неделя',
        day: 'день',
        agenda: 'повестка дня',
        date: 'дата',
        time: 'время',
        event: 'событие',
    };


    return (
        <div className='calendar'>
            <RBCalendar
                localizer={localizer}
                events={arrayEvents ? events : []}
                eventStyleGetter={eventStyleGetter}
                onSelectEvent={selectEvent} // Обработчик клика на событии
                views={['month', 'week', 'day']}
                messages={dictionary.messagesCalendar}
            />
        </div>
    )
}

export default Calendar;