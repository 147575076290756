const { v4: uuidv4 } = require('uuid');

// Функция для генерации уникального кода
async function generateUniqueCode() {
    const uniqueCode = uuidv4(); // Генерирует UUID
    return uniqueCode;
}

// Функция для получения или создания уникального кода
async function getOrGenerateUniqueCode() {
    // Пытаемся получить idDevice из локального хранилища
    const storedCode = await localStorage.getItem('idDevice');

    if (storedCode) {
        // Если idDevice уже существует, возвращаем его
        return storedCode;
    } else {
        // Если idDevice не существует, генерируем новый
        const newCode = await generateUniqueCode();
        // Сохраняем новый idDevice в локальном хранилище
        localStorage.setItem('idDevice', newCode);
        // Возвращаем новый idDevice
        return newCode;
    }
}


export {
    getOrGenerateUniqueCode,
}
