import { useAppContext } from '../../../context/AppContext';

function ItemWorks({ item }) {

    const { dictionary } = useAppContext();
    return (
        <div className='item__container item-works'>
            <p className='item__text'>{item.name}</p>
            <p className='item__text'>{item.cost} {dictionary.textCost}</p>
        </div>
    )
}

export default ItemWorks;