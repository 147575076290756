import InfoTooltip from "../InfoTooltip/InfoTooltip";
import ButtonWithBorder from "../ButtonWithBorder/ButtonWithBorder";
import { useAppContext } from '../../context/AppContext';

function FormRemove({ isOpen, closeForm, onRemove, isLoading }) {

    const { dictionary } = useAppContext();
    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={dictionary.formRemoveTitle}
            children={
                <div className="form-remove__container-buttons">
                    <ButtonWithBorder text={dictionary.textYes} onClick={onRemove} isLoading={isLoading} />
                    <ButtonWithBorder text={dictionary.textNo} onClick={closeForm} />
                </div>
            }
        />
    )
}

export default FormRemove;