import { regexStrokeInput, regexNumericInput, regexVIN, regexLogin } from "../../../../../utils/regex";
import userApi from "../../../../../utils/api/userApi";

function validationRecordName(recordName, setRecordNamevalidationText, dictionary) {
    if (recordName !== '') {
        if (!regexStrokeInput.test(recordName)) {
            setRecordNamevalidationText(dictionary.validationRegex);
            return false;
        } else {
            setRecordNamevalidationText('');
            return true;
        }
    } else {
        setRecordNamevalidationText(dictionary.validationValue);
        return false;
    }
}

function validationTypeOfWork(typeOfWork, arrayTypesOfWork, setValidationTypeOfWorkText, dictionary) {
    if (typeOfWork !== '') {
        const type = typeOfWork.value;
        if (!arrayTypesOfWork.includes(type)) {
            setValidationTypeOfWorkText(dictionary.validationRegex);
            return false;
        } else {
            setValidationTypeOfWorkText('');
            return true;
        }
    } else {
        setValidationTypeOfWorkText(dictionary.validationValue)
        return false;
    }
}

function validationMillage(millage, setMillageValidationText, dictionary) {
    if (millage !== '') {
        if (!regexNumericInput.test(millage)) {
            setMillageValidationText(dictionary.validationRegex);
            return false;
        } else {
            setMillageValidationText('');
            return true;
        }
    } else {
        setMillageValidationText(dictionary.validationValue);
        return false;
    }
}

let currentLogin = '';
async function validationClientLogin(clientLogin, setValidationClientLoginText, dictionary, idDevice) {
    let validation = false;
    if (clientLogin !== '') {
        if (regexLogin.test(clientLogin)) {
            if (clientLogin !== currentLogin) {
                try {
                    const data = await userApi.checkLogin(clientLogin, idDevice);
                    if (data.message === 'Логин не занят') {
                        setValidationClientLoginText(dictionary.validationFindLogin);
                        validation = false;
                    }
                } catch (error) {
                    if (error.message === 'Пользователь с таким логином найден') {
                        setValidationClientLoginText('');
                        currentLogin = clientLogin;
                        validation = true;
                    } else {
                        console.log(error.message);
                        setValidationClientLoginText(dictionary.errorMessage);
                        validation = false;
                    }
                }
            } else {
                setValidationClientLoginText('');
                validation = true;
            }
        } else {
            setValidationClientLoginText(dictionary.validationValue);
            validation = false;
        }
        return validation;
    }
}

let currentVIN = '';
async function validationVIN(VIN, setValidationVINText, dictionary, login, idDevice, validLogin) {
    try {
        let validation = false;
        if (validLogin) {
            if (VIN.length === 17) {
                if (!regexVIN.test(VIN)) {
                    if (setValidationVINText) {
                        setValidationVINText(dictionary.validationRegex);
                    }
                    validation = false;
                } else {
                    if (currentVIN !== VIN) {
                        await userApi.getUserTransportsByLoginUser(login, idDevice)
                            .then((data) => {
                                const userTransportsVINs = data.VINs;
                                if (userTransportsVINs.includes(VIN)) {
                                    setValidationVINText('');
                                    validation = true;
                                    currentVIN = VIN;
                                } else {
                                    setValidationVINText(dictionary.validationVINFind);
                                    validation = false;
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                    } else {
                        setValidationVINText('');
                        validation = true;
                    }
                }
            } else {
                if (setValidationVINText) {
                    setValidationVINText(dictionary.validationValueVIN);
                }
                validation = false;
            }
            return validation;
        } else {
            setValidationVINText(dictionary.validationValueLogin);
            validation = false;
        }
    }
    catch (error) {
        console.log(error)
    }
}


async function validationLoginAndVIN(VIN, setValidationVINText, setValidationClientLoginText, dictionary, clientLogin, idDevice) {
    try {
        let validLogin = await validationClientLogin(clientLogin, setValidationClientLoginText, dictionary, idDevice)
        let validVIN = await validationVIN(VIN, setValidationVINText, dictionary, clientLogin, idDevice, validLogin)

        if (validLogin && validVIN) {
            return true;
        } else {
            return false;
        }
    }
    catch (err) {
        console.log(err);
    }

}


function validationWorks(works) {
    if (works.length > 0) {
        return true;
    } else {
        return false;
    }
}

function validationTransportForm(isOpenAddTransportForm, isOpenUpdateTransportForm) {
    if (isOpenAddTransportForm || isOpenUpdateTransportForm) {
        return false;
    } else {
        return true;
    }
}

export { validationLoginAndVIN, validationRecordName, validationTypeOfWork, validationMillage, validationClientLogin, validationVIN, validationWorks, validationTransportForm };