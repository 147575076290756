import { useState } from 'react';
import InputDateTimePicker from '../InputDateTimePicker/InputDateTimePicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function DateRangePicker({ selectedDateOn, handleDateOnChange, selectedDateTo, handleDateToChange, validationTextDateOn, validationTextDateTo }) {

    return (

        <div className="date-range-picker">
            <div className='date-range-picker__input'>
                <div className='input-date-time-picker'>
                    <DatePicker
                        selected={selectedDateOn}
                        onChange={handleDateOnChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Время"
                        dateFormat="dd.MM.yyyy HH:mm"
                        className="date-picker"
                    />
                </div>
                <span className="input__text-error" id="error-firstname">{validationTextDateOn}</span>
            </div>
            <div className='date-range-picker__input'>
                <div className='input-date-time-picker'>
                    <DatePicker
                        selected={selectedDateTo}
                        onChange={handleDateToChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Время"
                        dateFormat="dd.MM.yyyy HH:mm"
                        className="date-picker"
                    />
                </div>
                <span className="input__text-error" id="error-firstname">{validationTextDateTo}</span>
            </div>
        </div >
    )
}

export default DateRangePicker;