import host from "./host";

class Api {
    constructor(data) {
        this.host = data.host;
    }

    // проверка статуса запроса
    _getResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            if (res.json()) {
                return res.json;
            } else {
                return Promise.reject(
                    `ошибка: ${res.status} - ${res.statusText}`
                );
            }
        }
    }

    // Получить транспорт по VIN
    getTransportByVIN(VIN, idDevice) {
        return fetch(`${this.host}/get-transport-by-vin/${VIN}/${idDevice}`, {
            method: 'GET',
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    // Получить список брендов
    getTransportsBrandList(brand, idDevice) {
        return fetch(`${this.host}/get-transport-brand/${brand}/${idDevice}`, {
            method: 'GET',
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });

    }

    // Получить список моделей
    getTransportsModelList(brand, idDevice) {
        return fetch(`${this.host}/get-transport-models/${brand}/${idDevice}`, {
            method: 'GET',
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });

    }

    // Создать транспорт
    createTransportByServiceEmployee(data, idDevice) {
        return fetch(`${this.host}/create-transport-by-service-employee/${idDevice}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                VIN: data.VIN,
                brand: data.brand,
                model: data.model,
                type: data.type
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    // Редактировать транспорт
    updateTransportByServiceEmployee(data, idDevice) {
        return fetch(`${this.host}/update-transport-by-service-employee/${idDevice}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                VIN: data.VIN,
                brand: data.brand,
                model: data.model,
                type: data.type
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

}

const transportApi = new Api({
    host: host,
});

export default transportApi;