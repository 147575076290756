import { useEffect, useState } from "react";
import { useAppContext } from '../../../../../../context/AppContext';

import InputSelectTypeTransport from "../InputSelectTypeTransport";
import ButtonWithBoarder from "../../../../../../components/ButtonWithBorder/ButtonWithBorder";
import InputText from "../../../../../../components/InputText/InputText";

import { validationStroke } from "../FormAddTransport/utils/validation";
import { checkTransportInfo } from "./utils/utils";


function FormCheckTransport({ transport, onSubmit }) {

    const [inputTransportBrand, setInputTransportBrand] = useState('');
    const [validationTransportBrandText, setValidationTransportBrandText] = useState('');
    const [inputTransportModel, setInputTransportModel] = useState('');
    const [validationTransportModelText, setValidationTransportModelText] = useState('');
    const [inputTransportType, setInputTransportType] = useState('');
    const [validationTransportTypeText, setValidationTransportTypeText] = useState('');
    const [validationFormText, setValidationFormText] = useState('');
    const [isValidationForm, setValidationForm] = useState(false);
    const [isInfoText, setInfoText] = useState(true);

    const { dictionary } = useAppContext();

    const options = [
        { label: dictionary.typeTransportPassanger, value: dictionary.valueTypeTransportPassanger },
        { label: dictionary.typeTransportCargo, value: dictionary.valueTypeTransportCargo },
        { label: dictionary.typeTransportBike, value: dictionary.valueTypeTransportBike },
        { label: dictionary.typeTransportOther, value: dictionary.valueTypeTransportOther },
    ]

    function submit() {
        const data = {
            brand: inputTransportBrand,
            model: inputTransportModel,
            type: inputTransportType,
        }
        onSubmit(data);
    }

    useEffect(() => {
        if (transport.brand) {
            setInputTransportBrand(transport.brand);
        }
        if (transport.model) {
            setInputTransportModel(transport.model);
        }
        if (transport.type) {
            const option = options.find(option => option.value === transport.type);
            setInputTransportType(option);
        }
    }, [])

    // Валидация
    useEffect(() => {

        let validationBrand = validationStroke(inputTransportBrand, setValidationTransportBrandText, setValidationFormText, dictionary);
        let validationModel = validationStroke(inputTransportModel, setValidationTransportModelText, setValidationFormText, dictionary);

        let currentType = '';
        if (transport.type) {
            const type = options.find(option => option.value === transport.type);
            currentType = type.value;
        }

        if (transport.brand !== inputTransportBrand || transport.model !== inputTransportModel || currentType !== inputTransportType.value) {
            setInfoText(true);
            if (validationBrand && validationModel) {
                setValidationForm(true);
            } else {
                setValidationForm(false);
            }
        } else {
            setValidationForm(false);
            setInfoText(true);
        }

    }, [inputTransportBrand, inputTransportModel, inputTransportType])

    return (
        <div className="form-add-transport form-add-transport__background-color-green">
            <p className="form-add-transport__title">{dictionary.formCheckTransportTitle}</p>
            <InputSelectTypeTransport
                value={inputTransportType}
                setSelectValue={setInputTransportType}
                validationText={validationTransportTypeText}
            />
            <InputText
                inputValue={inputTransportBrand}
                setInputChange={setInputTransportBrand}
                placeholder={dictionary.placeholderTransportBrand}
                validationText={validationTransportBrandText}
            />
            <InputText
                inputValue={inputTransportModel}
                setInputChange={setInputTransportModel}
                placeholder={dictionary.placeholderTransportModel}
                validationText={validationTransportModelText}
            />
            <div className="form-add-transport__button">
                <ButtonWithBoarder text={dictionary.buttonCheckTransport} onClick={submit} isDisabled={!isValidationForm} />
            </div>
            {validationFormText === '' ? <></> :
                <p className="form-add-transport__validation-text">{validationFormText}</p>}
            {isInfoText ?
                <p className="form-check-transport__info-text">{dictionary.textInfoFormCheckTransport}</p>
                : <></>}
        </div>
    )
}

export default FormCheckTransport;