import { regexStrokeInput } from "../../../../../../../utils/regex";

function validationStroke(input, setValidationTInputText, setValidationFormText, dictionary) {
    
    if (input === '') {
        setValidationTInputText(dictionary.validationValue);
        return false;
    } else {
        if (!regexStrokeInput.test(input)) {
            setValidationFormText(dictionary.validationRegex);
            return false;
        } else {
            setValidationFormText('');
            setValidationTInputText('');
            return true;
        }

    }
}

export { validationStroke }