import React from 'react';

const openWhatsAppChat = (phoneNumber) => {
    // Форматируем номер телефона для WhatsApp, убирая все нецифровые символы
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Формируем ссылку для открытия чата в WhatsApp
    const whatsappLink = `https://wa.me/+${formattedPhoneNumber}`;

    // Открываем ссылку в новой вкладке браузера
    window.open(whatsappLink, '_blank');
};

export {
    openWhatsAppChat,
}