import { useAppContext } from '../../context/AppContext';
import { useLocation } from 'react-router-dom';
import ButtonWithImg from '../../components/ButtonWithImg/ButtonWithImg';
import imgSupport from '../../images/support-svgrepo-com.svg';
import { sendEmail } from '../../utils/email/emailUtils';
import { useEffect, useState } from 'react';

function Footer({ }) {

    const { dictionary, dataUser } = useAppContext();
    const location = useLocation();

    const [isSupportButtonShow, setIsSupportButtonShow] = useState(false);

    useEffect(() => {
        if (location.pathname === '/') {
            setIsSupportButtonShow(true);
        } else {
            setIsSupportButtonShow(false);
        }
    }, [location]);

    const recipientEmail = dictionary.emailSupport;
    const themeEmail = 'support-service';
    const textEmail = `${dictionary.emailText} ${dataUser.login}, `;

    function sendEmailSupport() {
        sendEmail(recipientEmail, themeEmail, textEmail)
    }

    return (
        <footer className="footer">
            <p className='footer__text'>{dictionary.textTitleApp}</p>
            {isSupportButtonShow ?
                <ButtonWithImg img={imgSupport} onClick={sendEmailSupport} />
                : <></>}
        </footer>
    )
}

export default Footer;