import InputSelect from "../../../../../components/InputSelect/InputSelect";

import { useAppContext } from '../../../../../context/AppContext';

function InputSelectTypeTransport({ setSelectValue, value, validationText }) {

    const { dictionary } = useAppContext();

    function handleInputChange() { } // заглушка

    const options = [
        { label: dictionary.typeTransportPassanger, value: dictionary.valueTypeTransportPassanger },
        { label: dictionary.typeTransportCargo, value: dictionary.valueTypeTransportCargo },
        { label: dictionary.typeTransportBike, value: dictionary.valueTypeTransportBike },
        { label: dictionary.typeTransportOther, value: dictionary.valueTypeTransportOther },
    ]

    return (
        <InputSelect options={options} setSelectValue={setSelectValue} value={value} placeholder={dictionary.placeholderTypeTransport} validationText={validationText} handleInputChange={handleInputChange} />
    )
}

export default InputSelectTypeTransport;