function filterArrayByString(arr, searchString) {
  searchString = searchString.toLowerCase(); // Преобразуем строку к нижнему регистру для сравнения без учета регистра
  return arr.filter((item) => {
    // Проверяем совпадение в client.login или transport.VIN (без учета регистра)
    return (
      item.client.login.toLowerCase().includes(searchString) ||
      item.transport.VIN.toLowerCase().includes(searchString)
    );
  });
}

function sortArray(data) {
  const sortedData = data.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    // Сравниваем даты, чтобы ближайшие даты были в начале
    return dateB - dateA;
  })
  return sortedData;
}

function findEarliestDateWithStartOfDay(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return null; // Возвращаем null, если массив пустой или некорректный
  }

  // Начинаем сравнивать с первой даты в массиве
  let earliestDate = new Date(data[0].date);
  earliestDate.setHours(0, 0, 0, 0); // Устанавливаем начало дня

  for (let i = 1; i < data.length; i++) {
    const currentDate = new Date(data[i].date);
    currentDate.setHours(0, 0, 0, 0); // Устанавливаем начало дня для текущей даты

    if (currentDate < earliestDate) {
      earliestDate = currentDate;
    }
  }

  return earliestDate;
}

function findLatestDateWithEndOfDay(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return null; // Возвращаем null, если массив пустой или некорректный
  }

  // Начинаем сравнивать с первой даты в массиве
  let latestDate = new Date(data[0].date);
  latestDate.setHours(23, 59, 59, 999); // Устанавливаем конец дня

  for (let i = 1; i < data.length; i++) {
    const currentDate = new Date(data[i].date);
    currentDate.setHours(23, 59, 59, 999); // Устанавливаем конец дня для текущей даты

    if (currentDate > latestDate) {
      latestDate = currentDate;
    }
  }

  return latestDate;
}

function filterObjectsByDateRange(data, startDate, endDate) {
  if (!Array.isArray(data) || !startDate || !endDate) {
    return []; // Если массив пустой или даты не переданы, возвращаем пустой массив
  }

  const filteredData = data.filter((obj) => {
    const objDate = new Date(obj.date);
    return objDate >= startDate && objDate <= endDate;
  });

  return filteredData;
}

function compareDatesWithoutTime(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Устанавливаем время на полночь
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);

  if (d1.toString() === d2.toString()) {
    return true;
  } else {
    return false;
  }
}

function getDaysDifference(startDate, endDate) {
  // Разница в миллисекундах между датами
  const timeDifference = endDate - startDate;

  // Рассчитываем количество дней (1 день = 24 часа * 60 минут * 60 секунд * 1000 миллисекунд)
  const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

  return daysDifference;
}

export {
  filterArrayByString,
  sortArray,
  findEarliestDateWithStartOfDay,
  findLatestDateWithEndOfDay,
  filterObjectsByDateRange,
  compareDatesWithoutTime,
  getDaysDifference,
}