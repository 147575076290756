function sendEmail(to, subject, body) {
    const recipientEmail = encodeURIComponent(to);
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    const mailtoLink = `mailto:${recipientEmail}?subject=${encodedSubject}&body=${encodedBody}`;

    // Открываем почтовый клиент
    window.location.href = mailtoLink;
}

const supportEmail = "info@auto-dnevnik.ru";

export { sendEmail, supportEmail }