import { useAppContext } from '../../../context/AppContext';
import ItemWorks from "../ItemWorks/ItemWorks";

function ListWorks({ array }) {
    const { dictionary } = useAppContext();
    return (
        <div className="list-works">
            <p className="item__title">{dictionary.textListWorks}</p>
            {
                array.map((item) => {
                    return (
                        <div key={item.id}>
                            <ItemWorks item={item} />
                        </div>
                    )
                })
            }
        </div>
    )

}

export default ListWorks;