import { regexLogin, regexStrokeInput } from "../../../../../utils/regex";
import userApi from "../../../../../utils/api/userApi";

async function validationLogin(clientLogin, idDevice, setValidationClientLoginText, dictionary) {
    let validationLogin = '';
    if (clientLogin !== '') {
        if (regexLogin.test(clientLogin)) {

            await userApi.checkLogin(clientLogin, idDevice)
                .then((data) => {
                    if (data.message === 'Логин не занят') {
                        setValidationClientLoginText(dictionary.validationFindLogin);
                        validationLogin = false;
                    }
                })
                .catch((error) => {
                    if (error.message === 'Пользователь с таким логином найден') {
                        setValidationClientLoginText('');
                        validationLogin = true;
                    } else {
                        console.log(error)
                        setValidationClientLoginText(dictionary.errorMessage);
                        validationLogin = false;
                    }
                })
        }
    } else {
        setValidationClientLoginText(dictionary.validationValue);
        validationLogin = false;
    }
    return validationLogin;
}

function validationDateOn(dateRecord, setValidationDateRecordText, dictionary) {
    const currentTime = new Date();
    const nextMonth = new Date(currentTime);
    nextMonth.setMonth(currentTime.getMonth() + 1);
    if (dateRecord < currentTime) {
        setValidationDateRecordText(dictionary.validationTimeEarly);
        return false;
    } else if (dateRecord > nextMonth) {
        setValidationDateRecordText(dictionary.validationTimeNext);
        return false;
    } else {
        setValidationDateRecordText('');
        return true;
    }

}

function validationDateTo(dateRecord, dateOn, setValidationDateRecordText, dictionary) {
    const currentTime = new Date();
    const nextDay = new Date(currentTime);
    nextDay.setDate(currentTime.getDate() + 7);
    if (dateRecord < dateOn) {
        setValidationDateRecordText(dictionary.validationTimeDateToEarly);
        return false;
    } else if (dateRecord > nextDay) {
        setValidationDateRecordText(dictionary.validationTimeDateToNext);
        return false;
    } else {
        setValidationDateRecordText('');
        return true;
    }

}

function validationRecordName(recordName, setRecordNamevalidationText, dictionary) {
    if (recordName !== '') {
        if (!regexStrokeInput.test(recordName)) {
            setRecordNamevalidationText(dictionary.validationRegex);
            return false;
        } else {
            setRecordNamevalidationText('');
            return true;
        }
    } else {
        setRecordNamevalidationText(dictionary.validationValue);
        return false;
    }
}

export { validationLogin, validationDateOn, validationDateTo, validationRecordName }