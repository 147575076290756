import InfoTooltip from "../InfoTooltip/InfoTooltip";
import ButtonWithImg from "../ButtonWithImg/ButtonWithImg";

import imgEdit from "../../images/edit-2-svgrepo-com.svg";
import imgDelete from "../../images/trash-alt-svgrepo-com.svg";


function FormChoiceEditRemove({ isOpen, closeForm, text, onEdit, onRemove }) {
    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={text}
            children={
                <div className="form-choice-edit-remove__container-button">
                    <ButtonWithImg img={imgEdit} onClick={onEdit} />
                    <ButtonWithImg img={imgDelete} onClick={onRemove} />
                </div>
            }
        />
    )
}

export default FormChoiceEditRemove;