function filterObjectsByKeyword(array, keyword) {
    // Преобразуем ключевое слово в нижний регистр для регистронезависимого поиска
    const lowercaseKeyword = keyword.toLowerCase();

    // Фильтруем массив объектов
    const filteredArray = array.filter((obj) => {
        const { login, name, phoneNumber } = obj;

        // Преобразуем поля в нижний регистр и проверяем, содержит ли хотя бы одно из полей ключевое слово
        return (
            login.toLowerCase().includes(lowercaseKeyword) ||
            name.toLowerCase().includes(lowercaseKeyword) ||
            phoneNumber.toLowerCase().includes(lowercaseKeyword)
        );
    });

    return filteredArray;
}

function sortArray(data) {
    const sortedData = data.sort((a, b) => {
        const dateA = new Date(a.dateVisited);
        const dateB = new Date(b.dateVisited);
        // Сравниваем даты, чтобы ближайшие даты были в начале
        return dateB - dateA;
    })
    return sortedData;
}


export { filterObjectsByKeyword, sortArray }