import React from 'react';
import Select from 'react-select';

function InputSelect({ options, setSelectValue, value, placeholder, validationText, handleInputChange }) {

    const handleChange = (selectedOption) => {
        if (setSelectValue) {
            setSelectValue(selectedOption);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            fontSize: '16px', // Пример задания размера текста
            fontFamily: '"Inter",Arial, sans-serif', // Пример задания шрифта
            marginTop: '10px',
            border: 'none', // Пример пользовательских стилей
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)', // Устанавливаем нижнюю грань рамки с желаемым цветом
            borderRadius: 0,
            padding: 0,
        })
    };

    return (
        <>
            <Select
                options={options}
                onChange={handleChange}
                onInputChange={handleInputChange}
                value={value}
                placeholder={placeholder}
                styles={customStyles} // Передача пользовательских стилей
                className='input-select'
            />
            <span className="input__text-error" id="error-firstname">{validationText}</span>
        </>
    );
}

export default InputSelect;
