import host from "./host";

class Api {
    constructor(data) {
        this.host = data.host;
    }

    // проверка статуса запроса
    _getResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            return res.json().then((errorJson) => {
                const error = new Error(errorJson.message);
                error.json = errorJson;
                error.status = res.status;
                return Promise.reject(error);
            });
        }
    }

    // получить клиентов сервиса
    getClientsServiceByServiceEmployee(idUser, idDevice) {
        return fetch(`${this.host}/get-clients-service-by-service-employee/${idDevice}`, {
            method: 'GET',
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    updateClientsServiceByServiceEmployee(idUser, idDevice, data) {
        return fetch(`${this.host}/update-client-service-by-service-employee/${idDevice}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: data.idClient,
                nameClient: data.name,
                phoneNumberClient: data.phoneNumber,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    getMaintenancesByServiceEmployee(idUser, idDevice) {
        return fetch(`${this.host}/get-maintenances-by-service-employee/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include', // добавляем заголовок Authorization с токеном
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    getMaintenancesByServiceEmployeeByDateRange(dateOn, dateTo, idDevice) {
        return fetch(`${this.host}/get-maintenances-by-service-employee-by-date-range/${dateOn}/${dateTo}/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include', // добавляем заголовок Authorization с токеном
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    getMaintenancesByServiceEmployeeByVINOrClientLogin(vinOrLogin, idDevice) {
        return fetch(`${this.host}/get-maintenances-by-service-employee-by-vin-login/${vinOrLogin}/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include', // добавляем заголовок Authorization с токеном
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

}

const serviceApi = new Api({
    host: host,
});

export default serviceApi;