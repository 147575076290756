import InputSelect from "../../../../../components/InputSelect/InputSelect";
import Loader from "../../../../../components/Loader/Loader";

import { useAppContext } from '../../../../../context/AppContext';

function InputSelectModelTransport({ value, setSelectValue, placeholder, validationText, models, isLoading }) {

    const { dictionary } = useAppContext();

    let options = [
        {
            label: dictionary.unknownBrand,
            value: dictionary.unknownBrand,
        }
    ]

    if (models) {
        models.forEach(record => {
            const model = {
                label: record.name,
                value: record.name,
            }
            options.push(model);

        });
    }

    return (
        <div className="input-with-loader">
            <InputSelect options={options} setSelectValue={setSelectValue} value={value} placeholder={placeholder} validationText={validationText} />
            {isLoading ?
                <div className="input-select__loader">
                    <Loader customStyle={'loader__gray__w25h25'} />
                </div>
                : <></>}
        </div>
    )
}

export default InputSelectModelTransport;