import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/AppContext';

import ButtonNavigationBack from '../../components/ButtonNavigationBack/ButtonNavigationBack';
import ButtonWithBorder from '../../components/ButtonWithBorder/ButtonWithBorder';
import FormAddCustomerRecord from "./components/FormAddCustomerRecord/FormAddCustomerRecord";
import FormSelectCustomerRecord from "./components/FormSelectCustomerRecord/FormSelectCustomerRecord";
import InfoTooltip from "../../components/InfoTooltip/InfoTooltip";
import Calendar from "../../components/Calendar/Calendar";

import maintenanceApi from "../../utils/api/maintenanceApi";

function CustomerRecord({ }) {

    // Данные
    const [selectedItem, setSelectedItem] = useState('');
    const [bokingMaintenances, setBookingMaintenances] = useState([]);
    const [isOpenFormCustomerRecord, setOpenFormCustomerRecord] = useState(false);
    // Лоадеры
    const [isLoadingAddRecord, setLoadingAddRecord] = useState(false);
    const [isLoadingFormSelectCustomerRecord, setLoadingFormSelectCustomerRecord] = useState(false);
    const [isLoadingUpdateRecord, setLoadingUpdateRecord] = useState(false);
    const [isLoadingRemoveRecord, setLoadingRemoveRecord] = useState(false);
    // Формы и инфотул
    const [isOpenInfoTooltip, setOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');
    const [isOpenFormSelectCustomerRecord, setOpenFormSelectCustomerRecord] = useState(false);
    const [textFormSelectCustomerRecord, setTextFormSelectCustomerRecord] = useState('');

    const { dictionary, idUser, idDevice } = useAppContext();
    const navigate = useNavigate();

    function onClickBackPage(e) {
        e.preventDefault();
        navigate('/');
    }

    function openFormAddCustomerRecord() {
        setOpenFormCustomerRecord(true);
    }

    function closeFormAddCustomerRecord() {
        setOpenFormCustomerRecord(false);
    }

    function closeInfoTooltip() {
        setOpenInfoTooltip(false);
        setTextInfoTooltip('');
    }

    function openInfoTool(text) {
        setOpenFormCustomerRecord(false);
        setOpenFormSelectCustomerRecord(false);
        setOpenInfoTooltip(true);
        setTextInfoTooltip(text);
    }

    function addRecord(data) {
        setLoadingAddRecord(true);
        maintenanceApi.createBookingMaintenancesByServiceEmployee(idUser, idDevice, data)
            .then((data) => {
                setLoadingAddRecord(false);
                openInfoTool(dictionary.succesfull);
                const updateBookings = [...bokingMaintenances, data.bookingMaintenance];
                setBookingMaintenances(updateBookings);
            })
            .catch((err) => {
                console.log(err);
                setLoadingAddRecord(false);
                openInfoTool(dictionary.errorMessage);
            })

    }

    function removeItem() {
        setLoadingRemoveRecord(true);
        const idRecord = selectedItem.id;
        maintenanceApi.deleteBookingMaintenancesByServiceEmployee(idUser, idRecord, idDevice)
            .then(() => {
                const arrayUpdate = bokingMaintenances.filter((item) => item._id !== idRecord);
                setBookingMaintenances(arrayUpdate);
                openInfoTool(dictionary.succesfull);
                setLoadingRemoveRecord(false);
            })
            .catch((err) => {
                console.log(err);
                openInfoTool(dictionary.errorMessage);
                setLoadingRemoveRecord(false);
            })
    }

    function updateItem(nameBooking) {
        setLoadingUpdateRecord(true);
        const idRecord = selectedItem.id;
        maintenanceApi.updateBookingMaintenancesByServiceEmployee(idUser, idRecord, idDevice, nameBooking)
            .then((data) => {
                console.log(data);
                const arrayUpdate = bokingMaintenances.map((item) => {
                    if (item._id === idRecord) {
                        const update = data.updateBooking;
                        return update;
                    } else {
                        return item;
                    }
                })
                setBookingMaintenances(arrayUpdate);
                openInfoTool(dictionary.succesfull);
                setLoadingRemoveRecord(false);
            })
            .catch((err) => {
                console.log(err);
                openInfoTool(dictionary.errorMessage);
                setLoadingRemoveRecord(false);
            })

    }

    function closeFormSelectCustomerRecord() {
        setOpenFormSelectCustomerRecord(false);
        setLoadingUpdateRecord(false);
        setLoadingRemoveRecord(false);
    }

    function selectEvent(e) {
        setSelectedItem(e);
        setOpenFormSelectCustomerRecord(true);
        setTextFormSelectCustomerRecord(e.title);
    }

    // Наполнить массив записей на сервис на месяц вперед
    useEffect(() => {
        maintenanceApi.getBookingMaintenancesByServiceEmployee(idUser, idDevice)
            .then((data) => {
                const booking = data.bookingMaintenances;
                setBookingMaintenances(booking);
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <section className='customer-record'>
            <ButtonNavigationBack onClick={onClickBackPage} />
            <ButtonWithBorder text={dictionary.buttonAddCustomerRecord} onClick={openFormAddCustomerRecord} />
            <FormAddCustomerRecord
                onSubmit={addRecord}
                isOpen={isOpenFormCustomerRecord}
                closeForm={closeFormAddCustomerRecord}
                isLoading={isLoadingAddRecord}
            />
            <div className="customer-record__calendar">
                <Calendar arrayEvents={bokingMaintenances} handleSelectEvent={selectEvent} />
            </div>
            <InfoTooltip isOpen={isOpenInfoTooltip} closeInfoTool={closeInfoTooltip} text={textInfoTooltip} />
            <FormSelectCustomerRecord
                isOpen={isOpenFormSelectCustomerRecord}
                closeForm={closeFormSelectCustomerRecord}
                text={textFormSelectCustomerRecord}
                removeItem={removeItem}
                updateItem={updateItem}
                selectedItem={selectedItem}
                isLoadingRemove={isLoadingRemoveRecord}
                isLoadingUpdate={isLoadingUpdateRecord}
            />
        </section>
    )

}

export default CustomerRecord;