import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/AppContext';

import ButtonNavigationBack from "../../components/ButtonNavigationBack/ButtonNavigationBack";
import ListItems from "./ListItems/ListItems";
import SearcForm from "../../components/SearchForm/SearchForm";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import FormChoiceEditRemove from "../../components/FormChoiceEditRemove/FormChoiceEditRemove";
import FormUpdateMaintenance from "./FormUpdateMaintenance/FormUpdateMaintenance";
import FormRemove from "../../components/FormRemove/FormRemove";
import InfoTooltip from "../../components/InfoTooltip/InfoTooltip";
import Loader from "../../components/Loader/Loader";

import serviceApi from "../../utils/api/serviceApi";
import maintenanceApi from "../../utils/api/maintenanceApi";
import { sortArray, compareDatesWithoutTime, getDaysDifference } from './utils/maintenanceUtils';

function Maintenances({ }) {

    const [maintenances, setMaintenances] = useState([]);
    const [searchingStroke, setSearchingStroke] = useState('');
    const [dateOn, setDateOn] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [dateArrayOn, setDateArrayOn] = useState(new Date());
    const [dateArrayTo, setDateArrayTo] = useState(new Date());
    const [selectedMaintenance, setSelectedMaintenance] = useState(undefined);
    // Формы
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');
    const [isOpenFormChoiceEditRemove, setIsOpenFormChoiceEditRemove] = useState(false);
    const [isOpenFormUpdateMaintenance, setIsOpenFormUpdateMaintenance] = useState(false);
    const [isOpenFormRemove, setIsOpenFormRemove] = useState(false);
    const [validationTextDateRangePicker, setValidationTextDateRangePicker] = useState('');
    // Лоадеры
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUpdateMaintenance, setIsLoadingUpdateMaintenance] = useState(false);
    const [isLoadingFormRemove, setIsLoadingFormRemove] = useState(false);

    const navigate = useNavigate();
    const { dictionary, idUser, idDevice } = useAppContext();

    function onClickBackPage(e) {
        e.preventDefault();
        navigate('/');
    }

    function search() {
        if (searchingStroke !== '') {
            setIsLoading(true);
            serviceApi.getMaintenancesByServiceEmployeeByVINOrClientLogin(searchingStroke, idDevice)
                .then((data) => {
                    const array = data.maintenances;
                    const sortedArray = sortArray(array);
                    setMaintenances(sortedArray);
                    setIsLoading(false);
                })
                .catch((err) => {
                    setMaintenances([]);
                    console.log(err);
                    setIsLoading(false);
                })
        } else {
            const formattedThirtyDaysAgo = dateArrayOn.toISOString().slice(0, 10);
            const formattedThirtyDaysLater = dateArrayTo.toISOString().slice(0, 10);
            fillArray(formattedThirtyDaysAgo, formattedThirtyDaysLater);
        }
    }

    // Наполнить массив записей

    // Наполнить массив записей в зависимости от дат
    function fillArray(startDate, endDate) {
        setIsLoading(true);
        serviceApi.getMaintenancesByServiceEmployeeByDateRange(startDate, endDate, idDevice)
            .then((data) => {
                const array = data.maintenances;
                const sortedArray = sortArray(array);
                setMaintenances(sortedArray);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        // Получить сегодняшнюю дату
        const today = new Date();

        // Получить дату, отстоящую на 30 дней назад
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);

        // Получить дату, отстоящую на 30 дней вперед
        const thirtyDaysLater = new Date(today);
        thirtyDaysLater.setDate(today.getDate() + 30);

        // Преобразовать даты в строковый формат, если необходимо
        const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().slice(0, 10);
        const formattedThirtyDaysLater = thirtyDaysLater.toISOString().slice(0, 10);

        setDateOn(thirtyDaysAgo);
        setDateTo(thirtyDaysLater);
        setDateArrayOn(thirtyDaysAgo);
        setDateArrayTo(thirtyDaysLater);
        fillArray(formattedThirtyDaysAgo, formattedThirtyDaysLater)
    }, [])

    useEffect(() => {
        const comparedDateOn = compareDatesWithoutTime(dateArrayOn, dateOn)
        const comparedDateTo = compareDatesWithoutTime(dateArrayTo, dateTo)

        const startDate = new Date(dateOn);
        const endDate = new Date(dateTo);
        const daysDifference = getDaysDifference(startDate, endDate);

        if (startDate > endDate) {
            setDateOn(endDate);
        }

        if (daysDifference < -61) {
            // Получить дату, отстоящую на 60 дней ранее
            const sixtyDaysAgo = new Date(startDate);
            sixtyDaysAgo.setDate(startDate.getDate() - 60);
            setDateTo(sixtyDaysAgo);
        }

        if (daysDifference > 61) {
            // Получить дату, отстоящую на 60 дней вперед
            const sixtyDaysLater = new Date(startDate);
            sixtyDaysLater.setDate(startDate.getDate() + 60);
            setDateTo(sixtyDaysLater)
        }

        if (daysDifference > 61 || daysDifference < -61) {
            setValidationTextDateRangePicker(dictionary.validationTextDateRangePicker)
        } else {
            // setValidationTextDateRangePicker('');
        }

        if (!comparedDateOn || !comparedDateTo) {
            const startDate = dateOn.toISOString();
            const endDate = dateTo.toISOString();
            fillArray(startDate, endDate)
        }

    }, [dateOn, dateTo])


    function onClickItem(item) {
        const currentDate = new Date();
        const dateMaintenance = new Date(item.date);
        const daysDifference = getDaysDifference(dateMaintenance, currentDate);

        if (daysDifference > 45) {
            openInfoTooltip(dictionary.errorMessageDaysDifferenceMaintenancesUpdate)
        } else {
            setSelectedMaintenance(item);
            openFormChoiceEditRemove();
        }

    }

    // Формы
    function openFormChoiceEditRemove() {
        setIsOpenFormChoiceEditRemove(true);
    }

    function closeFormChoiceEditRemove() {
        setIsOpenFormChoiceEditRemove(false);
    }

    function openEditForm() {
        openFormUpdateMaintenance()
    }

    function openRemoveForm() {
        openFormRemove();
    }

    function updateMaintenance(updateData) {
        setIsLoadingUpdateMaintenance(true);
        maintenanceApi.updateMaintenance(idUser, idDevice, updateData)
            .then((data) => {
                setIsOpenFormUpdateMaintenance(false);
                openInfoTooltip(dictionary.succesfull);
                setIsLoadingUpdateMaintenance(false);
                console.log(data.updateMaintenance)
                const updateMaintenance = data.updateMaintenance;
                const updateArray = maintenances.map((maintenance) => {
                    if (maintenance._id === updateMaintenance._id) {
                        return updateMaintenance;
                    } else {
                        return maintenance;
                    }
                });
                const sortedArray = sortArray(updateArray);
                setMaintenances(sortedArray);
                setIsOpenFormUpdateMaintenance(false);
                openInfoTooltip(dictionary.succesfull);
            })
            .catch((err) => {
                console.log(err);
                openInfoTooltip(dictionary.errorMessage);
                setIsOpenFormUpdateMaintenance(false);
                setIsLoadingUpdateMaintenance(false);
            })
    }

    function openFormUpdateMaintenance() {
        setIsOpenFormUpdateMaintenance(true);
        closeInfoTooltip();
        closeFormChoiceEditRemove();
    }

    function closeFormUpdateMaintenance() {
        setIsOpenFormUpdateMaintenance(false);
        setIsLoadingUpdateMaintenance(false);
    }

    function openFormRemove() {
        setIsOpenFormRemove(true);
        setIsOpenFormChoiceEditRemove(false);
    }

    function closeFormRemove() {
        setIsOpenFormRemove(false);
        setIsLoadingFormRemove(false);
    }

    function removeItem() {
        setIsLoadingFormRemove(true);
        const idMaintenance = selectedMaintenance._id;
        maintenanceApi.removeMaintenancesByServiceEmployee(idUser, idMaintenance, idDevice)
            .then((data) => {
                const filtredArray = maintenances.filter((item) => item._id !== idMaintenance)
                const sortedArray = sortArray(filtredArray);
                setMaintenances(sortedArray);
                setIsLoadingFormRemove(false);
                closeFormRemove();
                openInfoTooltip(dictionary.succesfull);
            })
            .catch((err) => {
                console.log(err);
                setIsLoadingFormRemove(false);
                closeFormRemove();
                openInfoTooltip(dictionary.errorMessage);
            })
    }

    function openInfoTooltip(text) {
        setIsOpenInfoTooltip(true);
        setTextInfoTooltip(text);
    }

    function closeInfoTooltip() {
        setIsOpenInfoTooltip(false);
        setTextInfoTooltip('');
    }

    return (
        <section className="maintenances">
            <ButtonNavigationBack onClick={onClickBackPage} />
            <h2 className="clients__title">{dictionary.textMaintenances}</h2>
            <div className="maintenances__search-form">
                <SearcForm
                    onSubmit={search}
                    placeholder={dictionary.placeholderSearchFormMaintenances}
                    text={searchingStroke}
                    setText={setSearchingStroke}
                />
                <DateRangePicker
                    selectedDateOn={dateOn}
                    handleDateOnChange={setDateOn}
                    selectedDateTo={dateTo}
                    handleDateToChange={setDateTo}
                />
                {validationTextDateRangePicker !== '' ?
                    <p className="input__text-error">{validationTextDateRangePicker}</p>
                    : <></>}
            </div>
            {isLoading && maintenances.length > 0 ?
                <Loader customStyle={'loader__gray__w25h25'} />
                :
                <ListItems array={maintenances} onClickItem={onClickItem} />}
            <InfoTooltip isOpen={isOpenInfoTooltip} closeInfoTool={closeInfoTooltip} text={textInfoTooltip} />
            <FormChoiceEditRemove isOpen={isOpenFormChoiceEditRemove} closeForm={closeFormChoiceEditRemove} onEdit={openEditForm} onRemove={openRemoveForm} text={selectedMaintenance ? selectedMaintenance.name : ''} />
            {isOpenFormUpdateMaintenance ?
                <FormUpdateMaintenance onSubmit={updateMaintenance} isLoading={isLoadingUpdateMaintenance} isOpen={isOpenFormUpdateMaintenance} closeForm={closeFormUpdateMaintenance} text={''} item={selectedMaintenance} />
                : <></>}
            <FormRemove isOpen={isOpenFormRemove} closeForm={closeFormRemove} onRemove={removeItem} isLoading={isLoadingFormRemove} />
        </section>
    )
}

export default Maintenances;