import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/AppContext';

import ButtonNavigationBack from "../../components/ButtonNavigationBack/ButtonNavigationBack";

import serviceApi from "../../utils/api/serviceApi";
import ListClients from "./components/ListClients/ListClients";
import FormClient from "./components/FormClient/FormClient";
import SearchForm from "../../components/SearchForm/SearchForm";
import Loader from "../../components/Loader/Loader";
import InfoTooltip from "../../components/InfoTooltip/InfoTooltip";

import { filterObjectsByKeyword, sortArray } from "./clientsUtils";


function Clients() {

    const [clients, setClients] = useState([]);
    const [filtredArrayClients, setFiltredArrayClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState({});
    const [isOpenFormClients, setIsOpenFormClients] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [isFilter, setFilter] = useState(false);
    const [searchingStroke, setSearchingStroke] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');

    const navigate = useNavigate();
    const { dictionary, idUser, idDevice } = useAppContext();

    function onClickBackPage(e) {
        e.preventDefault();
        navigate('/');
    }

    function onClickItem(data) {
        openFormClient();
        setSelectedClient(data);
    }

    function openFormClient() {
        setIsOpenFormClients(true);
    }

    function closeFormClient() {
        setIsOpenFormClients(false);
    }

    function closeInfoTool() {
        setIsOpenInfoTooltip(false);
    }

    function updateClient(data) {
        setIsLoadingUpdate(true);
        serviceApi.updateClientsServiceByServiceEmployee(idUser, idDevice, data)
            .then((data) => {
                const updateArray = clients.map((client) => {
                    if (client._id === selectedClient._id) {
                        let update = data.updateService.clients.find(client => client._id === selectedClient._id);
                        console.log(update)
                        return update;
                    } else {
                        return client;
                    }
                })
                setClients(updateArray);
                closeFormClient();
                setIsLoadingUpdate(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoadingUpdate(false);
            });
    }

    function search() {
        if (searchingStroke !== '') {
            setFilter(true);
            const filtredArray = filterObjectsByKeyword(clients, searchingStroke);
            const sortedData = sortArray(filtredArray);
            setFiltredArrayClients(sortedData);
        } else {
            setFilter(false);
            setFiltredArrayClients('');
        }
    }

    useEffect(() => {
        setIsLoading(true);
        serviceApi.getClientsServiceByServiceEmployee(idUser, idDevice)
            .then((data) => {
                const sortedArray = sortArray(data)
                setClients(sortedArray);
                setIsLoading(false);
            })
            .catch((error) => {
                { console.log(error) }
                setIsLoading(false);
                setTextInfoTooltip(dictionary.errorMessage)
                setIsOpenInfoTooltip(true);
            })
    }, [])

    return (
        <section className="clients">
            <ButtonNavigationBack onClick={onClickBackPage} />
            <h2 className="clients__title">{dictionary.textClients}</h2>
            <div className="clients__search-form">
                <SearchForm
                    onSubmit={search}
                    text={searchingStroke}
                    setText={setSearchingStroke}
                />
            </div>
            {isLoading ?
                <Loader customStyle={'loader__gray__w25h25'} />
                :
                <ListClients array={isFilter ? filtredArrayClients : clients} onClickItem={onClickItem} />}
            <FormClient clientData={selectedClient} isOpen={isOpenFormClients} closeForm={closeFormClient} update={updateClient} isLoading={isLoadingUpdate} />
            <InfoTooltip isOpen={isOpenInfoTooltip} text={textInfoTooltip} closeInfoTool={closeInfoTool} />
        </section>
    )
}

export default Clients;