import { useEffect, useState } from "react";

import InputText from "../../../../components/InputText/InputText";
import ButtonWithBorder from "../../../../components/ButtonWithBorder/ButtonWithBorder";
import ListWorks from "../../../../components/ListWorks/ListWorks";

import { useAppContext } from '../../../../context/AppContext';

import { regexStrokeInput, regexNumericInput } from "../../../../utils/regex";

function FormAddWork({ onSubmit, arrayWorks, onClickRemoveItem }) {

    const [recordName, setRecordName] = useState('');
    const [recordNamevalidationText, setRecordNamevalidationText] = useState('');
    const [cost, setCost] = useState('');
    const [costValidationText, setCostValidationText] = useState('');
    const [isValidForm, setIsValidForm] = useState(false);
    const [messageValidForm, setMessageValidForm] = useState('');

    const { dictionary } = useAppContext();

    function submit() {
        if (isValidForm) {
            const id = arrayWorks.length + 1;
            const works = {
                id: id.toString(),
                name: recordName,
                cost: cost,
            }
            onSubmit(works);
            setRecordName('');
            setCost('');
        }
    }

    // Валидация
    useEffect(() => {

        let validName = false;
        let validCost = false;

        if (recordName !== '') {
            if (!regexStrokeInput.test(recordName)) {
                setRecordNamevalidationText(dictionary.validationRegex);
                validName = false;
            } else {
                setRecordNamevalidationText('');
                validName = true;
            }
        } else {
            setRecordNamevalidationText(dictionary.validationValue);
            validName = false;
        }

        if (cost !== '') {
            if (!regexNumericInput.test(cost)) {
                setCostValidationText(dictionary.validationRegex);
                validCost = false;
            } else {
                setCostValidationText('');
                validCost = true;
            }
        } else {
            setCostValidationText(dictionary.validationValue);
            validCost = false;
        }



        if (validName && validCost) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }

        if (arrayWorks.length === 0) {
            setMessageValidForm(dictionary.validationFormWorks)
        } else {
            setMessageValidForm('')
        }


    }, [recordName, cost, arrayWorks]);

    return (
        <div className="form-add-work__container">
            <div className="form-add-work">
                <p className="form-add-work__title">{dictionary.formAddWorksTitle}</p>
                <div className="form-add-record__container-input">
                    <InputText
                        inputValue={recordName}
                        setInputChange={setRecordName}
                        placeholder={dictionary.placeholderNameWork}
                        label={dictionary.labelNameWork}
                        validationText={recordNamevalidationText}
                    />
                    <InputText
                        inputValue={cost}
                        setInputChange={setCost}
                        placeholder={dictionary.placeholderCost}
                        label={dictionary.placeholderCost}
                        validationText={costValidationText}
                    />
                </div>
                <ButtonWithBorder text={dictionary.buttonAddWork} isLoading={false} isDisabled={!isValidForm} onClick={submit} />
                <p className="form-add-work__error-message">{messageValidForm}</p>
            </div>
            <ListWorks array={arrayWorks} onClickRemoveItem={onClickRemoveItem} />
        </div>
    )
}

export default FormAddWork;