import { useAppContext } from '../../../context/AppContext';
import ListWorks from '../ListWorks/ListWorks';

function Item({ item, onClick }) {

    const { dictionary } = useAppContext();
    console.log(item)
    const type = item.type;
    const date = new Date(item.date);
    const userLocale = navigator.language;
    const formattedDate = date.toLocaleDateString(userLocale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });
    const millage = item.millage;
    const name = item.name;
    const works = JSON.parse(item.works);
    let cost = 0;
    works.forEach((work) => cost += Number(work.cost));
    let author = "";
    if (item.author.name) {
        author = item.author.name;
    }
    const client = item.client.login;

    function handleClick() {
        onClick(item);
    }

    return (
        <div className="item" onClick={handleClick}>
            <div className="item__container">
                <p className='item__text'>{dictionary.textType}: {type}</p>
                <p className='item__text'>{formattedDate}</p>
            </div>
            <div className="item__container">
                <p className='item__title'>{name}</p>
            </div>
            <div className="item__container-works">
                <ListWorks array={works} />
            </div>
            <div className="item__container">
                <p className='item__text'>{dictionary.textClient}: {client}</p>
                <p className='item__text'>{dictionary.textAuthor}: {author} </p>
            </div>
            <div className="item__container">
                <p className='item__text'>{dictionary.textMillage}: {millage} {dictionary.textKm}</p>
                <p className='item__text'>{dictionary.textSummary}: {cost} {dictionary.textCost}</p>
            </div>
        </div>
    )
}

export default Item;