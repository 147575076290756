import ItemListClients from "../ItemListClients/ItemListClients";

function ListClients({ array, onClickItem }) {

    function onClick(item) {
        onClickItem(item);
    }

    return (
        <div className="list-clients">
            {array.map((item) => {

                const login = item.login;
                let name = '';
                let phoneNumber = '';
                let dateVisited = '';
                if (item.name) {
                    name = item.name;
                }
                if (item.phoneNumber) {
                    phoneNumber = item.phoneNumber;
                }
                if (item.dateVisited) {

                    const date = new Date(item.dateVisited);
                    const userLocale = navigator.language;
                    const formattedDate = date.toLocaleDateString(userLocale, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    });

                    dateVisited = formattedDate;
                }

                return (
                    <div key={item._id}>
                        <ItemListClients id={item._id} login={login} name={name} phoneNumber={phoneNumber} dateVisited={dateVisited} onClick={() => onClick(item)} />
                    </div>
                )
            })}
        </div>
    )
}

export default ListClients;